import LazyLoad from "vanilla-lazyload";

class LazyLoadManager {
    constructor() {
        this.activate();
    }

    activate() {
    
        const observer = new MutationObserver( (mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.attributeName == 'src') {
                    let el = $(mutation.target);
                    let img = new Image();

                    img.src = $(el).attr('data-src');

                    img.onload =function () {
                        const width = this.width;
                        const height = this.height;

                        $(el).attr('data-original-width', width);
                        $(el).attr('data-original-height', height);
                    };
                } 
            }
        });

        $('img.lazy').each(function() {
            observer.observe(this, { childList: true, attributes: true });
        });
        

        this.instance = new LazyLoad({
            elements_selector: '.lazy'
        });

        /*
        $('.lazy').each(function() {
            let img = new Image();
            img.src = $(this).attr('data-bg');
            img.onload = function () {
                const width = this.width;
                const height = this.height;

                $(this).attr('data-width', width);
                $(this).attr('data-height', height);
                console.log(this.src, 'width =' + width + ', height = ' + height);
            };
        })
        */
    }

    update() {
        this.instance.update();
    }
}

export default LazyLoadManager;