export default class BotBlocker {
  constructor(formSelector = 'form.touchpoint') {
    this.forms = document.querySelectorAll(formSelector);

    // 🔒 Lista di campi da bloccare (puoi aggiungere altri qui)
    this.blockedEntries = [
      { field: 'contact[email]', value: 'ericjonesmyemail@gmail.com' },
      { field: 'contact[mobile]', value: '555-555-1212' }
      // Aggiungi altri se necessario
    ];

    if (this.forms.length > 0) {
      this.init();
    }
  }

  isBlocked(form) {
    const formData = new FormData(form);
    return this.blockedEntries.some(entry => {
      const fieldValue = formData.get(entry.field);
      return fieldValue && fieldValue.trim().toLowerCase() === entry.value.toLowerCase();
    });
  }

  init() {
    this.forms.forEach(form => {
      form.addEventListener('submit', e => {
        if (this.isBlocked(form)) {
          e.preventDefault();
          console.warn('Form submission blocked: user identified as bot');
          alert('Invio bloccato per motivi di sicurezza.');
        }
      });
    });
  }
}

