
class GeolocationManager{
    constructor(autoinit = false) {
        this.position = undefined;
    
        if (autoinit) {
            this.getLocation();
        }
    }

    getLocation(callback) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                $.proxy((position) => {
                    this.position = position;
                    
                    if (typeof callback == "function") {
                        callback();
                    }
                    
                    if (window.dataLayer) {
                        window.dataLayer.push({'latitude': this.position.coords.latitude, 'longitude': this.position.coords.longitude});
                    }
                }, this), 
                $.proxy((error) => { 
                    this.position = error; 
                    
                    if (typeof callback == "function") {
                        callback();
                    }
                }, this)
            );

        } else {
            console.error("Geolocation is not supported?");
        }
    }
}

export default GeolocationManager;
