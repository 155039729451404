
class GoogleMapsManager {
    constructor(el) {
        this.el = el;
        
        this.init();
    }

    init() {
        try {
            const locationsraw = JSON.parse($(this.el).attr('data-locations'));

            this.locations = locationsraw.locations;
            this.geocoder = new google.maps.Geocoder();
            this.map = undefined;
            this.markers = new Array();
            this.center = {lat: this.locations[0].lat, lng: this.locations[0].lng};
            this.zoom = 12;
            this.themeurl = $(this.el).attr('data-theme');

            this.createmap();
        } catch (e) {
            console.log('location passed generating error', $(this.el).attr('data-locations'));
            console.error(e.message);
        }
        
    }

    createmap() {
        const style = [
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "color": "#545454"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "saturation": "-87"
                    },
                    {
                        "lightness": "-40"
                    },
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f0f0f0"
                    },
                    {
                        "saturation": "-22"
                    },
                    {
                        "lightness": "-16"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "saturation": "-52"
                    },
                    {
                        "hue": "#00e4ff"
                    },
                    {
                        "lightness": "-16"
                    }
                ]
            }
        ];
        const options = {
            zoom: this.zoom,
            center: this.center,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,

            styles:style
        };

        this.map = new google.maps.Map(this.el.get(0), options);

        this.addMapListeners();
        this.createMarkers();
    }
    
    addMapListeners() {
        google.maps.event.addListener(this.map, 'zoom_changed', $.proxy(function () {
            this.zoom = this.map.zoom;
        }, this));
    }

    createMarkers () {
        $.each(this.locations, $.proxy(function (key, value) {
            this.createMarkerWithCoords(value);
        }, this));
    }

    createMarkerWithCoords (value) {
        const iconPin = {
            url: this.themeurl+'dist/assets/icons/pinpointmap.svg',
            size: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 30),
            scaledSize: new google.maps.Size(30, 30)
        };
        
        const marker = new google.maps.Marker({
            map: this.map,
            position: new google.maps.LatLng(value.lat, value.lng),
            icon: iconPin
        });


        /*
        let content = "<div style='text-align: center;'>";
            content += "<h2 style='font-size:16px;font-weight:700; margin: 0; margin-bottom: 10px;'>" + value.title + "</h2>";
            content += "<p style='margin-bottom: 10px;'>"+ value.address +" "+ value.city +"</p>";
        content += "</div>";

        const infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 250
        });

        google.maps.event.addListener(marker, 'click', $.proxy(function () {
           infowindow.open(this.map, marker);
        }, this));
        */

        this.markers.push(marker);
    }
}

export default GoogleMapsManager;