class FAQManager {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {

    this.el.children(".question").on("click", function () {
      if ($(this).hasClass('open')) {
        $(this).removeClass('open');
      } else {
        $(this).addClass('open');
      }
    });
  }
}

export default FAQManager;
