class ModalizerManager {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.el.on("click", function (e) {
      e.preventDefault();
      const target = $($(this).attr("data-rel"));
      if (target.length > 0) {
        let modalwrapper = $("body").children(".modalwrapper");

        if (modalwrapper.length == 0) {
          $("body").append(
            '<div class="modalwrapper"><div class="modalcloser"></div><div class="modalpositioner"></div></div>'
          );
          modalwrapper = $("body").children(".modalwrapper");

          modalwrapper.children(".modalcloser").on("click", function () {
            $(".modalorigin").after(target);
            $(".modalorigin").detach();
            $(".modalwrapper").detach();
          });
        }

        target.after('<div class="modalorigin"></div>');
        target.detach();
        modalwrapper.children(".modalpositioner").empty().append(target);
      }
    });
  }
}

export default ModalizerManager;
