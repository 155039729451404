class InvieportManager {
    constructor() {
        this.els_to_be_monitored = document.querySelectorAll('[data-inviewport]');
        this.intersectionObserverOptions = {};  

        this.createInteractionObserver();
        this.listenMutations();
    }

    createInteractionObserver() {      
        this.intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                entry.target.classList.toggle("inviewport", entry.isIntersecting);
            });
        });

        this.startIntersectionObserving();
    }

    startIntersectionObserving() {
        this.els_to_be_monitored.forEach(el => {
            this.intersectionObserver.observe(el, this.intersectionObserverOptions);
        });
    }

    rebuildIntersectionObserved() {
        this.els_to_be_monitored = document.querySelectorAll('[data-inviewport]');
        this.intersectionObserver.disconnect();

        this.startIntersectionObserving();
    }

    listenMutations() {
        let mutationObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                window.ivm.handleMutation(mutation);
            });
        });

        mutationObserver.observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    }

    handleMutation(m) {
        let els = [];
        
        if ($(m.target).is('[data-inviewport]')) {
            els.push(el);
        }

        $(m.addedNodes).each((id, el) => {
            if ($(el).is('[data-inviewport]')) {
                els.push(el);
            }
        });
        
        if (els.length > 0) {
            this.rebuildIntersectionObserved();
        }
    }
}

module.exports = InvieportManager;