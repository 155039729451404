
class QRCodeManager {
    constructor(el) {
        this.el = el;
        this.qrapi = require('qrcode');
        this.init();
    }


    init() {
        if(this.el.attr('data-value').length == 0) return;
        
        //let loc = 'https://' + window.location.hostname + '/tools/out?d='+btoa(this.el.attr('data-value'));
        let loc = this.el.attr('data-value');
        
        //console.log('qrcode loc', loc);
        this.qrapi.toCanvas(loc, 
            {
                errorCorrectionLevel: 'H', 
                margin:0,
                color: {
                    dark: this.el.attr('data-colordark'),
                    light: this.el.attr('data-colorlight')
                }
            }, 
            (function (err, canvas) {
                if (err) throw err

                var container = document.getElementById('container')
                this.el.append(canvas)
            }).bind(this)
        )
    }
}

export default QRCodeManager;