class SearchSystemManager {
  constructor() {
    this.defaults = {};
    this.defaults.radius = 20;
    this.defaults.limit = 4;

    this.el = $(".component.searchsystem");
    if (this.el.length == 0) return;

    this.pro = false;
    if (this.el.hasClass("pro")) {
      this.pro = true;
    }

    this.map_el = $(".component.map.googlemapsaside");
    this.results_el = $(".async_content_list.aggregator");
    this.results_el.removeClass("deferred");
    this.submit_el = this.el.find(".cta");

    this.scope_anchors = this.el
      .find(".component.form-element.entity")
      .find("a");

    this.activity_search_el = $(
      "#" +
        this.el
          .find(".component.form-element.activity")
          .attr("data-searchablefield")
    );
    this.activity_results_el = this.el
      .find(".component.form-element.activity")
      .find("ul.options")
      .one();
    this.activity_results_el
      .find("li[data-value]:not(.selected)")
      .each(function () {
        $(this).hide();
      });

    if (this.pro) {
      this.richlabels = JSON.parse(this.el.attr("data-rl"));
      this.activity_results_el.parent().append('<div class="gridder"></div>');
      const gridder = this.activity_results_el.parent().children(".gridder");
      gridder.append(this.activity_results_el);
      this.activity_results_el.addClass("activity");
      this.activity_results_el.children("li").first().text(this.richlabels.ac);
      gridder.append(
        '<ul class="options club"><li class="search-welcome search-unclickable-element">' +
          this.richlabels.sf +
          "</li></ul>"
      );
      this.club_results_el = gridder.children(".club");

      if ($(window).width() < 576) {
        this.submit_el.append(this.richlabels.ctamobile);
      }
    }

    this.address_search_el = $(
      "#" +
        this.el
          .find(".component.form-element.address")
          .attr("data-searchablefield")
    );

    this.scope_search_el = this.el.find("#w");
    this.address_results_el = this.el
      .find(".component.form-element.address")
      .find("ul.options")
      .one();

    this.timeoutsearch = 0;
    this.timeoutseconds = 500;

    this.g_autocomplete_service = new google.maps.places.AutocompleteService();
    this.g_geocoder = new google.maps.Geocoder();

    this.pagetype = $("body").attr("data-type");
    this.pagehasmap =
      $("main.aggregator").children(".purelist").length == 0 ? true : false;

    this.addListeners();
    this.getRequestedLocality();
    if (this.pro) {
      this.showMainActivities();
      console.log("leggo usegeo", sessionStorage.getItem("usegeo"));
      console.log(
        'sessionStorage.getItem("usegeo") == true',
        sessionStorage.getItem("usegeo") == "true"
      );
      if (sessionStorage.getItem("usegeo") == "true") {
        console.log("lancio la geoloc automatica");
        setTimeout(function () {
          // questo workaround causerà continue chiamate a google, capire se legalmente possiamo salvare lat lng e indirizzo in sessionStorage per riusarli al volo
          $(".search-geo").trigger("click");
        }, 500);
      }
    }
  }

  getRequestedLocality() {
    let url = location.pathname.split("/");

    if (this.pagetype == "taxonomy") {
      this.country = url[2];
      this.scope = url[3];
      this.city = decodeURIComponent(url[4]);
      this.activity = url[5];

      if (typeof this.activity !== "undefined") {
        this.activityid = this.activity_results_el
          .find('li[data-value="' + this.activity + '"]')
          .attr("data-id");
      }

      this.autocompleteLocation(
        this.city.trim(),
        $.proxy(this.handleRequestedLocality, this)
      );

      if ($(window).width() < 992) {
        $("section.list").append('<div class="searchwrapper"></div>');
        $(".searchwrapper").append(this.el);
      }
    } else if (this.pagetype == "article" && !this.pro) {
      this.country = $("html").attr("lang");
      const k = this.scope_search_el.val().substring(0, 1);
      const map = JSON.parse(this.el.attr("data-" + k));
      this.scope = map[this.country];

      // **Salva la posizione originale prima di spostarlo**
      if (!this.el.data("original-parent")) {
        this.el.data("original-parent", this.el.parent());
        this.el.data("original-index", this.el.index());
      }

      // **Spostamento rimosso - lasciamo l'elemento dov'è**
    } else if (this.pagetype == "article" && this.pro) {
      this.country = $("html").attr("lang");
      this.scope = "club";
      this.launchClubSearch();

      // **Mantiene l'elemento nella sua posizione originale**
      if (this.el.data("original-parent")) {
        const parent = this.el.data("original-parent");
        const index = this.el.data("original-index");

        if (index >= 0) {
          this.el.detach().insertAfter(parent.children().eq(index));
        } else {
          parent.append(this.el);
        }
      }
    }
  }

  handleRequestedLocality(results) {
    //console.log('searchsystem.handleRequestedLocality', results);
    this.g_geocoder.geocode(
      { placeId: results[0].place_id },
      $.proxy(function (r, status) {
        const lat = r[0].geometry.location.lat();
        const lng = r[0].geometry.location.lng();

        this.address_results_el.find("[data-lat]").remove();
        this.addResultToUX(r, "address");
        $(this.address_results_el)
          .closest(".component.form-element")
          .children('input[type="text"]')
          .val(r[0].formatted_address);
        this.city = r[0].address_components[0].long_name;

        for (let i = 0; i < r[0].address_components.length; i++) {
          if (
            r[0].address_components[i].types[0] == "administrative_area_level_2"
          ) {
            this.province = r[0].address_components[i].short_name;
            $(".component.searchsystem").attr("data-province", this.province);
            break;
          }
        }

        this.launchClubSearch();
        this.changePageTitle(this.city, false);
        this.setPosition(lat, lng);

        //console.log('FLUSH PARTE DA handleRequestedLocality '+this.city);
        if (this.pagetype == "taxonomy" && !this.pagehasmap) {
          this.flush(
            lat,
            lng,
            false,
            $(".async_content_list.aggregator.clubs")
          );
          this.flush(
            lat,
            lng,
            false,
            $(".async_content_list.aggregator.products")
          );
          this.flush(
            lat,
            lng,
            false,
            $(".async_content_list.aggregator.events")
          );
        } else {
          this.flush(lat, lng);
        }
      }, this)
    );
  }

  flush(lat, lng, resetfrom, el = null) {
    //console.log('searchsystem.flush', el);
    if (el == null) {
      el = this.results_el;
    }

    try {
      let filters = JSON.parse(el.attr("data-filters"));

      filters.language = this.getGeofencingByCountry(filters.language);

      filters.near = {};
      filters.near.latitude = lat;
      filters.near.longitude = lng;
      filters.near.radius = this.defaults.radius * 1000;
      filters.near.mode = "point";

      if (this.activity) {
        filters["[=]custom.rootactivities.value"] = this.activityid;
      }

      if (resetfrom) {
        filters["listfrom"] = 0;
      }

      el.attr("data-filters", JSON.stringify(filters));

      //console.log('00 |||||||||||||||||||| window.ss.flush', el);
      window.ux.activateLazyContentList(el);
      this.searching = false;

      if (this.map_el.length > 0) {
        window.gmac.emptyMarkers();
        window.gmac.centerTo(lat, lng);
      }
    } catch (e) {
      console.error("Errore", e);
      console.log("Json", this.results_el.attr("data-filters"));
      console.log(this.results_el);
      return false;
    }
  }

  addListeners() {
    this.address_search_el.on(
      "keyup",
      $.proxy(function (e) {
        this.address_results_el.find(".search-status").show();
        //console.log('01 ^^^^^^^^^^^^^^^^^^^^');
        //console.log('address_search_el keyup: key ', e.which);
        if (e.which == 229 || (e.which <= 90 && e.which >= 48)) {
          //console.log('io sono', this);
          clearTimeout(this.timeout);
          this.timeout = setTimeout(
            $.proxy(this.launchAddressSearch, this),
            this.timeoutseconds
          );
          //console.log('01 ^^^^^^^^^^^^^^^^^^^^');
          //console.log('ho impostato il timeout verso this.launchAddressSearch', this.timeout);
        } else if (e.which == 13) {
          //console.log('01 ^^^^^^^^^^^^^^^^^^^^');
          //console.log('lancio la ricerca direttamente', this.timeout);
          clearTimeout(this.timeout);
          this.launchAddressSearch();
        }
      }, this)
    );

    this.address_search_el.on(
      "keydown",
      $.proxy(function (e) {
        //console.log('cancello il timeout', this.timeout);
        clearTimeout(this.timeout);
      }, this)
    );

    this.address_search_el.on("focus", function () {
      $(this).addClass("focus");
    });

    this.address_search_el.on(
      "paste",
      $.proxy(function () {
        setTimeout(
          $.proxy(function () {
            var t = this.address_search_el.val();
            this.address_search_el.val(t.trim());
            this.timeout = setTimeout(
              $.proxy(this.launchAddressSearch, this),
              this.timeoutseconds
            );
          }, this),
          100
        );
      }, this)
    );

    const labels = JSON.parse($("main").attr("data-labels"));
    const opp =
      this.address_search_el.attr("id") == "l_q"
        ? this.address_search_el.closest(".options")
        : this.address_search_el.parent().find(".options");
    opp.append(
      '<li class="search-element search-geo">' + labels.aroundme + "</li>"
    );

    opp.children(".search-geo").on(
      "click",
      $.proxy(function (e) {
        e.stopPropagation();
        $(".search-geo").text("Searching...");
        window.glm.getLocation($.proxy(this.handleBrowserLocation, this));
      }, this)
    );

    this.activity_search_el.on(
      "keyup",
      $.proxy(function (e) {
        let srel = this.activity_results_el.find(".search-status");
        let sel = this.activity_search_el;

        if (this.activity_search_el.val().length == 0) {
          this.activity_results_el.find("li[data-value]").hide();
          srel.show();
        } else {
          srel.hide();
        }

        if (
          (e.which <= 90 && e.which >= 48) ||
          e.which >= 32 ||
          e.which == 13 ||
          (e.which == 8 && sel.val().length > 0 && this.pro)
        ) {
          const v = sel.val().replace(" ", "-").toLowerCase();

          if (v.length < 2) return;

          this.activity_results_el.find("li[data-value]").hide();
          this.activity_results_el.find('li[data-value*="' + v + '"]').show();

          if (this.pro) {
            this.activity_results_el
              .find(".search-welcome.search-unclickable-element")
              .show();
            this.timeoutclub = setTimeout(
              $.proxy(this.launchClubSearch, this),
              this.timeoutseconds
            );

            if (
              this.activity_results_el.find("li[data-value]:visible").length ==
              0
            ) {
              this.activity_results_el
                .find(".search-element.search-status")
                .show();
            }
          }
        } else if (e.which == 8 && sel.val().length == 0) {
          // remove activity
          delete this.activity;
          delete this.activityid;

          // cambio il link generale di scope, rimuovendo l'attività
          this.scope_anchors.each(function () {
            let parts = $(this).attr("href").split("/");
            if (parts.length == 6) {
              parts.pop();
            }
            $(this).attr("href", parts.join("/"));
          });

          const op = this.activity_results_el.closest(
            ".component.form-element"
          );
          op.children('input[type="text"]').val("");
          op.children('input[type="hidden"]').val("");

          if (this.pagetype == "taxonomy" && this.pagehasmap) {
            this.changePageTitle(this.city, true);

            let filters = JSON.parse(this.results_el.attr("data-filters"));
            delete filters["[=]custom.rootactivities.value"];
            this.results_el.attr("data-filters", JSON.stringify(filters));

            this.flush(window.gmac.center.lat, window.gmac.center.lng);
          } else if (this.pagetype == "taxonomy" && !this.pagehasmap) {
            this.changePageTitle(this.city, true);

            // aggiorno i club
            const clubs_el = $(".async_content_list.aggregator.clubs");
            let clubs_filters = JSON.parse(clubs_el.attr("data-filters"));
            delete clubs_filters["[=]custom.rootactivities.value"];
            clubs_el.attr("data-filters", JSON.stringify(clubs_filters));

            this.flush(
              window.gmac.center.lat,
              window.gmac.center.lng,
              false,
              clubs_el
            );

            // aggiorno i products
            const products_el = $(".async_content_list.aggregator.products");
            let products_filters = JSON.parse(products_el.attr("data-filters"));
            delete products_filters["[=]custom.rootactivities.value"];
            products_el.attr("data-filters", JSON.stringify(products_filters));

            this.flush(
              window.gmac.center.lat,
              window.gmac.center.lng,
              false,
              products_el
            );

            // aggiorno gli eventi
            const events_el = $(".async_content_list.aggregator.products");
            let events_filters = JSON.parse(events_el.attr("data-filters"));
            delete events_filters["[=]custom.rootactivities.value"];
            events_el.attr("data-filters", JSON.stringify(events_filters));

            this.flush(
              window.gmac.center.lat,
              window.gmac.center.lng,
              false,
              products_el
            );
          }
        }
      }, this)
    );

    this.activity_search_el.on(
      "keydown",
      $.proxy(function (e) {
        //console.log('cancello il timeout', this.timeout);
        clearTimeout(this.timeoutclub);
      }, this)
    );

    this.activity_search_el.on("focus", function () {
      $(this).addClass("focus");
      setTimeout(function () {
        window.ss.launchClubSearch();
      }, 500);
    });

    this.submit_el.on(
      "click",
      $.proxy(function (e) {
        this.navigateToResults();
      }, this)
    );

    $(document).on("click", "li[data-lat]", function () {
      window.ss.handleLocalitySelection(this);
    });
    $(document).on(
      "click",
      ".activity .optionpositioner li[data-value]",
      function (e) {
        // console.log('ho cliccato su un bottone di attività', e);
        window.ss.handleActivitySelection(this);
      }
    );
  }

  navigateToResults() {
    let destination = "/mosaic/" + this.country + "/" + this.scope;
    if (typeof this.city != typeof undefined) {
      destination += "/" + this.city.toLowerCase();
    } else {
      destination += "/" + this.el.attr("data-defaultcity");
    }

    if (typeof this.activity != typeof undefined) {
      destination += "/" + this.activity.toLowerCase();
    }

    window.location.href = destination;
  }

  handleBrowserLocation() {
    if (window.glm.position.code == 1) {
      //console.log('Utente rifiuta geoloc ', window.glm.position);
      $(".search-geo").text(window.glm.position.message);
      setTimeout(function () {
        $(".search-geo").hide();
      }, 3000);
    } else {
      $(".search-geo").hide();

      const latlng = {
        lat: window.glm.position.coords.latitude,
        lng: window.glm.position.coords.longitude,
      };

      this.g_geocoder.geocode(
        { location: latlng },
        $.proxy(function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            //$('.search-geo').hide();
            this.clearResultsFromUX("address");
            this.addResultToUX(results, "address", true);
            setTimeout(function () {
              //console.log('imposto usegeo a true');
              sessionStorage.usegeo = true;
            }, 1000);
          } else {
            console.warn("Can't find address: " + status);
          }
        }, this)
      );
    }
  }

  handleOptionAutoSelect(optionpositioner) {
    const searchfield = $(optionpositioner).siblings('[data-role="search"]');
    const hasfocus = searchfield.hasClass("focus");
    const domain = $(optionpositioner).parent().hasClass("activity")
      ? "activity"
      : "locality";
    const optiongroup =
      domain == "activity"
        ? $(optionpositioner).find(".options.activity")
        : $(optionpositioner).find(".options");

    //console.log('---------------------------');
    //console.log('sono optionpositioner', optionpositioner);
    //console.log('il mio search box è ', searchfield);
    //console.log('il mio search box è focused?', hasfocus);
    //console.log('In che dominio siamo?', domain);
    //console.log('Quale optiongroup devo controllare per attivare una option?', optiongroup);

    if (searchfield.val().length == 0) {
      // do nothing, user has cancelled
    } else if (hasfocus) {
      let optionselected = $(optiongroup).find(
        "li.selected[data-value]:visible"
      );

      // non ho nulla di selezionato visibile, devo selezionare automaticamente
      if (optionselected.length == 0) {
        // 01- cerco il primo visible
        //console.log('cerco il primo visibile')
        optionselected = $(optiongroup).find("li[data-value]:visible").first();

        // 02- cerco un precedente selected
        if (optionselected.length == 0) {
          //console.log('cerco un precedente selected')
          optionselected = $(optiongroup).find("li.selected").first();
        }

        // 03- cerco il primo
        if (optionselected.length == 0) {
          //console.log('cerco il primo della lista')
          optionselected = $(optiongroup).find("li[data-value]").first();
        }

        //console.log('triggero click su', optionselected);
        //console.log('++++++++++++++++++++++++++++');
        //console.log('handleOptionAutoSelect triggero autoclick');
        optionselected.trigger("click");
      }
    }
  }

  showMainActivities() {
    this.activity_results_el
      .find(".search-welcome.search-unclickable-element")
      .show();
    this.activity_results_el
      .find('[data-id="576d49558b39ce424530b825"]')
      .show(); //zumba
    this.activity_results_el
      .find('[data-id="576d49468b39ce424530b824"]')
      .show(); // yoga
    this.activity_results_el
      .find('[data-id="576d481b8b39ce424530b81a"]')
      .show(); // pilates
    // this.activity_results_el.find('[data-id="576d47138b39ce424530b813"]').show(); // padel
    this.activity_results_el
      .find('[data-id="576c69ee9bdcfed13cdb7966"]')
      .show(); // tennis
    this.activity_results_el
      .find('[data-id="58d663aa4dc51cfa393c81ef"]')
      .show(); // calcetto

    this.activity_results_el
      .find(".search-element.search-status")
      .text(this.richlabels.cta);
  }

  launchClubSearch() {
    const v =
      this.activity_search_el.val().length > 0
        ? this.activity_search_el.val().toLowerCase()
        : "asd";

    const options = {
      type: "GET",
      dataType: "json",
      url: "/searchClubs?l=" + $("html").attr("lang") + "&q=" + v,
      context: this,
      success: function (j) {
        this.club_results_el
          .find(".search-welcome.search-unclickable-element")
          .show();
        this.club_results_el.find("[data-warn]").remove();
        this.club_results_el.find("[data-url]").remove();

        if (j.length == 0) {
          this.club_results_el.append(
            '<li data-warn="warning" class="search-element search-status">No results found</li>'
          );
        } else {
          const n = 5;

          // Shuffle array
          const shuffled = j.sort(() => 0.5 - Math.random());
          // Get sub-array of first n elements after shuffled
          let selected = shuffled.slice(0, n);

          for (let i in selected) {
            let club = selected[i];
            this.club_results_el.append(
              '<li data-rel="club" data-url="' +
                club.url +
                '"><img src="' +
                club.img +
                '" width="40" height="40" class="club" /><a href="/' +
                $("html").attr("lang") +
                club.url +
                '" title="' +
                club.name +
                '" class="club">' +
                club.name +
                '<span class="address">' +
                club.add +
                "</span></a></li>"
            );
          }
        }
      },
      error: function (j) {
        console.log("error", j);
      },
    };

    let province = $(".component.searchsystem").attr("data-province");
    if (typeof province != typeof undefined) {
      options.url += "&p=" + province;
    } else {
      province = $(".component.searchsystem").attr("data-defaultprovince");
      if (typeof province != typeof undefined) {
        options.url += "&p=" + province;
      }
    }
    //console.log('**************');
    //console.log('launchClubSearch, provincia', province)

    let activity = $(".options.activity")
      .children("li[data-id].selected:visible")
      .first()
      .attr("data-id");
    if (typeof activity != typeof undefined) {
      options.url += "&r=" + activity;
    } else {
      activity = $(".options.activity")
        .children("li[data-id]:visible")
        .first()
        .attr("data-id");

      //console.log('activity visible', activity);
      if (typeof activity != typeof undefined) {
        options.url += "&r=" + activity;
      }
    }

    $.ajax(options);
  }

  launchAddressSearch() {
    //console.log('02 ^^^^^^^^^^^^^^^^^^^^');
    //console.log('launchAddressSearch, valore da ricercare', this.address_search_el.val().trim())

    this.address_results_el.find(".search-status").show();
    this.autocompleteLocation(
      this.address_search_el.val().trim(),
      $.proxy(this.handleAutoCompleteResults, this)
    );
  }

  setPosition(lat, lng) {
    this.position = new google.maps.LatLng(lat, lng);
  }

  getPosition() {
    return this.position;
  }

  autocompleteLocation(s, cb) {
    if (this.lastSearch == s || this.searching) {
      //console.log('03 ^^^^^^^^^^^^^^^^^^^^');
      //console.log('autocompleteLocation: fermo perché lastsearch è uguale'+(this.lastSearch == s)+' oppure this.searching '+this.searching);
      return;
    }

    let countryrestriction = this.getGeofencingByCountry(this.country);

    const request = {
      input: s,
      componentRestrictions: { country: countryrestriction },
    };
    //console.log('03 ^^^^^^^^^^^^^^^^^^^^');
    //console.log('autocompleteLocation: lancio autocomplete con questi parametri', request);

    this.g_autocomplete_service.getPlacePredictions(request, cb);
    this.searching = true;
  }

  getGeofencingByCountry(c) {
    // patch per far girare le versioni estere su dati italiani
    let geofence = c;
    if (c == "en") {
      geofence = ["it", "fr", "es"];
    } else if (c == "fr") {
      geofence = ["it", "fr", "es"];
    } else if (c == "es") {
      geofence = ["it", "fr", "es"];
    } else if (c == "ca") {
      geofence = ["it", "fr", "es"];
    } else if (c == "it") {
      geofence = ["it", "fr", "es"];
    }

    //console.log('SearchSystem::getGeofencingByCountry', {'c':c, 'geofence':geofence});
    return geofence;
  }

  handleAutoCompleteResults(r) {
    //console.log('al termine della autocomplete rimuovo', this.address_results_el.find('[data-lat]'));

    this.address_results_el.find(".search-status").hide();
    this.address_results_el.find("[data-lat]").remove();

    //console.log('04 ^^^^^^^^^^^^^^^^^^^^');
    //console.log('handleAutoCompleteResults: al termine della autocomplete ottengo', r);

    for (let i in r) {
      let result = r[i];

      if (
        result.types[0] == "locality" ||
        result.types[0] == "route" ||
        result.types[0] == "premise" ||
        result.types[0] == "street_address"
      ) {
        //console.log('trovato '+result.description, result.types)
        this.g_geocoder.geocode(
          { placeId: result.place_id },
          $.proxy(function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              this.addResultToUX(results, "address");
            } else {
              console.warn("Can't find address: " + status);
            }
          }, this)
        );
      } else {
        //console.log('scarto '+result.description, result.types)
      }
    }
    this.searching = false;
  }

  addResultToUX(r, scope, autoclick) {
    let locality;
    let province;
    for (let i = 0; i < r[0].address_components.length; i++) {
      if (r[0].address_components[i].types[0] == "locality") {
        locality = r[0].address_components[i].long_name;
      }
      if (
        r[0].address_components[i].types[0] == "administrative_area_level_2"
      ) {
        province = r[0].address_components[i].short_name;
      }
    }

    if (locality.length == 0) return;

    //console.log('05 ^^^^^^^^^^^^^^^^^^^^');
    //console.log('addResultToUX: aggiungo a UX', r[0].formatted_address);

    this[scope + "_results_el"].append(
      '<li data-locality="' +
        locality +
        '" data-province="' +
        province +
        '" data-lat="' +
        r[0].geometry.location.lat() +
        '" data-lng="' +
        r[0].geometry.location.lng() +
        '" data-value="' +
        r[0].formatted_address +
        '">' +
        r[0].formatted_address +
        "</li>"
    );

    if (autoclick) {
      setTimeout(
        $.proxy(function () {
          //console.log('++++++++++++++++++++++++++++');
          //console.log('addResultToUX triggero autoclick');

          this[scope + "_results_el"]
            .children("li[data-locality]")
            .first()
            .trigger("click");
        }, this),
        500
      );
    }
  }

  clearResultsFromUX(scope) {
    this[scope + "_results_el"].children("li[data-locality]").remove();
  }

  handleLocalitySelection(el) {
    this.city = $(el).attr("data-locality");
    this.province = $(el).attr("data-province");
    //console.log('imposto usegeo a false');
    sessionStorage.usegeo = false;

    $(".component.searchsystem").attr("data-province", this.province);

    if (this.pro && $(window).width() < 576) {
      //console.log('**************');
      //console.log('handleLocalitySelection: non redirigo perché mobile', this.province);
      return;
    }

    if (this.pagetype == "taxonomy") {
      const lat = $(el).attr("data-lat");
      const lng = $(el).attr("data-lng");

      $(".component.searchsystem").attr(
        "data-province",
        $(el).attr("data-province")
      );

      this.changePageTitle(this.city, true);
      this.launchClubSearch();

      //console.log('FLUSH PARTE DA handleLocalitySelection');
      this.flush(lat, lng);
    } else if (!this.pro) {
      let map,
        k = this.pro ? "c" : this.scope_search_el.val().substring(0, 1);

      try {
        map = JSON.parse(this.el.attr("data-" + k));
      } catch (e) {
        console.error(
          "Errore nel parsing del json data-" + k,
          this.el.attr("data" + k)
        );
        console.log(this.el);
        return false;
      }

      let nu =
        "/mosaic/" +
        this.country +
        "/" +
        map[this.country] +
        "/" +
        this.city.toLowerCase();

      if (typeof this.activity != "undefined") {
        nu += "/" + encodeURIComponent(this.activity.toLowerCase());
      }

      window.location.href = nu;
    } else if (this.pro) {
      setTimeout(function () {
        $(document).trigger("click");
      }, 500);
    }
  }

  handleActivitySelection(el) {
    this.activity = $(el).attr("data-value");
    this.activityid = $(el).attr("data-id");

    if (this.pro && $(window).width() < 576) {
      //console.log('**************');
      //console.log('handleActivitySelection: non redirigo perché mobile');
      return;
    }

    // cambio il link generale di scope, includendo l'attività
    this.scope_anchors.each(function () {
      let parts = $(this).attr("href").split("/");
      if (parts.length == 5) {
        parts.push(window.ss.activity);
      } else {
        parts[5] = window.ss.activity;
      }
      $(this).attr("href", parts.join("/"));
    });

    if (this.pagetype == "taxonomy") {
      this.changePageTitle(this.city, true);

      let lat, lng;

      if (this.pagehasmap) {
        lat = window.gmac.center.lat;
        lng = window.gmac.center.lng;
      } else {
        const cl = $(".async_content_list.aggregator.clubs");
        const fltr = JSON.parse(cl.attr("data-filters"));
        lat = fltr.near.latitude;
        lng = fltr.near.longitude;
      }

      //console.log('FLUSH PARTE DA handleActivitySelection');
      if (this.pagetype == "taxonomy" && !this.pagehasmap) {
        this.flush(lat, lng, false, $(".async_content_list.aggregator.clubs"));
        this.flush(
          lat,
          lng,
          false,
          $(".async_content_list.aggregator.products")
        );
        this.flush(lat, lng, false, $(".async_content_list.aggregator.events"));
      } else {
        this.flush(lat, lng, true);
      }
    } else if (!this.pro) {
      // se siamo in altre pagine aspettiamo che l'utente selezioni la città
      if (typeof this.city == typeof undefined) return;

      let map,
        k = this.pro ? "c" : this.scope_search_el.val().substring(0, 1);

      try {
        map = JSON.parse(this.el.attr("data-" + k));
      } catch (e) {
        console.error(
          "Errore nel parsing del json data-" + k,
          this.el.attr("data" + k)
        );
        console.log(this.el);
        return false;
      }

      let nu =
        "/mosaic/" +
        this.country +
        "/" +
        map[this.country] +
        "/" +
        this.city.toLowerCase();

      if (typeof this.activity != "undefined") {
        nu += "/" + encodeURIComponent(this.activity.toLowerCase());
      }

      window.location.href = nu;
    } else if (this.pro) {
      setTimeout(function () {
        $(document).trigger("click");
      }, 500);
    }
  }

  changePageTitle(w, urladapt) {
    const elwhere = $("h1, h2").find("span.where");
    const elintrowhere = $("p.introduction").find("span.where");

    if (w == null) {
      const labels = JSON.parse($("main").attr("data-labels"));
      w = labels.aroundcurrentmap;

      elwhere.html(" " + w);
      elintrowhere.html(" " + w);
    } else {
      let append = "";
      if (!this.activity) append = ",";
      elwhere.html(" " + elwhere.attr("data-prepend") + " " + w);
      elintrowhere.html(
        " " + elintrowhere.attr("data-prepend") + " " + w + append
      );
    }

    const elwhat = $("h1, h2").find("span.what");
    const elintrowhat = $("p.introduction").find("span.what");
    const elctawhat = $("p.ctatitle").find("span.what");
    if (this.activity) {
      const actlabel = (
        this.activity.charAt(0).toUpperCase() + this.activity.slice(1)
      ).replace(/-/g, " ");
      elwhat.html(
        " " + elwhat.attr("data-prepend") + " " + actlabel.replace(/-/g, " ")
      );
      elintrowhat.html(
        " " + elintrowhat.attr("data-prepend").replace("*", actlabel) + ", "
      );
      elctawhat.html(actlabel.replace(/-/g, " "));

      $("ol.breadcrumb").children("li").last().children("span").text(actlabel);
    } else {
      elwhat.html("");
      elintrowhat.html("");
    }

    if (urladapt) {
      //console.log('inserisco '+$('h1').text().replace(/\s\s+/g, ' ')+' su ', $('head > title'));
      $("head > title").text($("h1").text().replace(/\s\s+/g, " "));

      let nu =
        "/mosaic/" +
        this.country +
        "/" +
        this.scope +
        "/" +
        encodeURIComponent(this.city.toLowerCase());
      if (typeof this.activity != "undefined") {
        nu += "/" + encodeURIComponent(this.activity.toLowerCase());
      }

      window.history.pushState("", "", nu);
    }

    if (this.pagetype == "taxonomy" && !this.pagehasmap) {
      const options = {
        type: "GET",
        dataType: "json",
        url:
          "/getSEOContentByActivity?activity=" +
          this.activity +
          "&city=" +
          this.city +
          "&lang=" +
          $("html").attr("lang"),
        context: this,
        success: function (j) {
          if (j == null) {
            $(".introductionwrapper").find("span.content").hide();
            $(".faqsection").hide();
          } else {
            $(".introductionwrapper").find("span.content").show();
            $(".faqsection").show();
          }

          $(".introductionwrapper").find("span.content").html(j.introduction);
          $(".faqwrapper").find(".faq").remove();

          for (let id in j.faq) {
            const faq = j.faq[id];
            const html =
              '<div class="faq" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question"><div class="q" itemprop="name">' +
              faq.q +
              '</div><div class="a" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"><div itemprop="text">' +
              faq.a +
              "</div></div>";
            $(".faqwrapper").append(html);
          }
        },
        error: function (j) {
          console.log("error", j);
        },
      };

      $.ajax(options);
    }
  }
}

export default SearchSystemManager;
