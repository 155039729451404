
class VideoControlsManager {
    constructor(el) {
        this.el = el;

        this.playbutton             = this.el.siblings('.control.play');
        this.pausebutton            = this.el.siblings('.control.pause');
        this.fullscreenbutton       = this.el.siblings('.control.fullscreen');
        this.mutebutton             = this.el.siblings('.control.mute');
        this.unmutebutton           = this.el.siblings('.control.unmute');

        this.activate();
    }

    activate() {
        this.playbutton.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            const video = $(this).siblings('video[data-id="'+$(this).attr('data-rel')+'"]');
            video[0].addEventListener('ended',
                function() {
                    video[0].load();
                    video.siblings('.control.play').show();
                    video.siblings('.control.pause').hide();
                    clearTimeout(video.siblings('.control.pause')[0].hideAfterStopMoving);
                    $(document).off('mousemove');
                    video.parent().removeClass('running');
                    if (video.parent().hasClass('monster')) {
                        $('nav').show();
                    }

                    if (video.parent().hasClass('monster')) {
                        var exitfullscreen = video[0].exitFullscreen || video[0].webkitExitFullscreen || video[0].mozCancelFullscreen || video[0].msCancelFullscreen || video[0].msExitFullscreen;
                        if (exitfullscreen) {
                            exitfullscreen.call(video[0]);
                        }
                    }
                }
            );

            $(this).hide();
            $(this).parent().addClass('running');

            video[0].play();

            if (video.parent().hasClass('monster')) {
                var fullscreen = video[0].requestFullscreen || video[0].webkitRequestFullscreen || video[0].mozRequestFullScreen || video[0].msRequestFullscreen;
                if (fullscreen) {
                    fullscreen.call(video[0]);
                }
                $('nav').hide();
            }

            var pause = $('.control.pause[data-rel="'+$(this).data('rel')+'"]');
            clearTimeout(pause[0].hideAfterStopMoving);
            $(document).on('mousemove', (function(e) {
                var ishover = $('.control.pause[data-rel="'+$(this).data('rel')+'"]:hover').length;
                if (ishover) return;

                $(this).stop().clearQueue().fadeIn();//.show();
                clearTimeout($(this)[0].hideAfterStopMoving);

                $(this)[0].hideAfterStopMoving = setTimeout(function() {
                    $('.control.pause').stop().clearQueue().fadeOut();
                }, 1000);

            }).bind(pause));
        });

        this.pausebutton.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            const video = $(this).siblings('video[data-id="'+$(this).attr('data-rel')+'"]');

            $(this).stop().clearQueue().fadeOut();
            $(this).parent().children('.play').show();
            video[0].pause();

            $(document).off('mousemove');
        });

        this.pausebutton.on('mouseleave', function() {
            $(this).stop().clearQueue().fadeOut();
        });

        this.pausebutton.on('mouseenter', function() {
            clearTimeout($(this)[0].hideAfterStopMoving);
        });

        this.fullscreenbutton.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            const video = $(this).siblings('video[data-id="'+$(this).attr('data-rel')+'"]');

            var fullscreen = video[0].requestFullscreen || video[0].webkitRequestFullscreen || video[0].mozRequestFullScreen || video[0].msRequestFullscreen;
            if (fullscreen) {
                fullscreen.call(video[0]);
            }
        });

        this.mutebutton.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            const video = $(this).siblings('video[data-id="'+$(this).attr('data-rel')+'"]');
            video[0].muted = false;
            $(this).hide();
            $(this).parent().children('.unmute').show();
        });

        this.unmutebutton.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            const video = $(this).siblings('video[data-id="'+$(this).attr('data-rel')+'"]');
            video[0].muted = true;
            $(this).hide();
            $(this).parent().children('.mute').show();
        });

        if (typeof this.el.attr('autoplay') !== typeof undefined) {
            this.playbutton.trigger('click');
        }

        if (typeof this.el.attr('muted') === typeof undefined) {
            this.mutebutton.trigger('click');
        }
    }
}

module.exports = VideoControlsManager;