
class GoogleMapsAsideManager {
    constructor(el) {
        this.el = el;
        this.center = { lat: 41.8858581, lng: 12.4827799 }
        this.zoomedval = 'auto'; // auto | integer
        this.pansearch = $('#pansearch');

        this.init();
    }

    init() {
        try {            
            const locationsraw = JSON.parse($(this.el).attr('data-locations'));

            this.locations = locationsraw.locations;
            this.geocoder = new google.maps.Geocoder();
            this.map = undefined;
            this.center = { lat: this.center.lat, lng: this.center.lng };
            this.zoom = 11;
            this.themeurl = $(this.el).attr('data-theme');

            this.createmap();
            this.listenMutations();
        } catch (e) {
            console.log('location passed generating error', $(this.el).attr('data-locations'));
            console.error(e.message);
        }
    }

    listenMutations() {
        let mutationObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                window.gmac.handleMutation(mutation);
            });
        });

        mutationObserver.observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    }

    handleMutation(m) {
        let el = $(m.target);
        if (el.hasClass('async_content_list') || el.hasClass('contentlist')) {
            el.find('[data-mappable]:not([data-mapped])').each(function() {
                if (!$(this).attr('data-distance')) return;

                window.gmac.createMarker($(this).attr('data-id'),  {'lat':$(this).attr('data-lat'),'lng':$(this).attr('data-lng'), 'dist':$(this).attr('data-distance'), 'type':$(this).attr('data-type')});

                $(this).attr('data-mapped', true);

                $(this).on('mouseenter', function () {
                    window.gmac.highlightMarker($(this).attr('data-id'), true);
                });
                $(this).on('mouseleave', function () {
                    window.gmac.highlightMarker($(this).attr('data-id'), false);
                });
            });

            // clono la paginazione creando un proxy visibile
            let pagel = el.find('.component.pagination').not('.summary');
            if (pagel.length > 0 && $(window).width() < 992) {
                let pw = $('section.list').find('.paginationwrapper');
                if (pw.length == 0) {
                    $('section.list').append('<div class="paginationwrapper"></div>');
                    pw = $('section.list').find('.paginationwrapper');
                }

                const nid = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 15);
                const pid = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 15);
                
                pw.html(pagel.clone());

                let n = pagel.find('.loadmore[data-action="next"]');
                let p = pagel.find('.loadmore[data-action="prev"]');
                n.attr('data-targetid', nid);
                p.attr('data-targetid', pid);

                let pagelproxy = pw.children('.pagination');
                pagelproxy.find('.loadmore').attr('data-role', 'proxy');
                pagelproxy.find('.loadmore[data-action="next"]').attr('data-target', nid);
                pagelproxy.find('.loadmore[data-action="prev"]').attr('data-target', pid);
            }
        } 
    }

    createmap() {
        const style = [
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "color": "#545454"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "saturation": "-87"
                    },
                    {
                        "lightness": "-40"
                    },
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f0f0f0"
                    },
                    {
                        "saturation": "-22"
                    },
                    {
                        "lightness": "-16"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "saturation": "-52"
                    },
                    {
                        "hue": "#00e4ff"
                    },
                    {
                        "lightness": "-16"
                    }
                ]
            }
        ];
        const options = {
            zoom: this.zoom,
            center: this.center,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,

            minZoom: this.zoom - 1,
            maxZoom: this.zoom + 4,
          
            styles: style
        };

        this.map = new google.maps.Map(this.el.get(0), options);
        this.bounds = new google.maps.LatLngBounds();
        this.markers = {};

        this.addMapListeners();
    }

    addMapListeners() {
        google.maps.event.addListener(this.map, 'zoom_changed', $.proxy(function () {
            if (!this.fittingbounds) {
                // fittingbounds è impostato al createMarker prima di chiamare fitBounds.
                // se è true, non vogliamo che venga chiamato handleMapGesture che causerebbe un nuovo flush
                // che richiamerebbe la createMarker, generando un ciclo infinito

                // per ora disattiviamo il caricamento dei risultati, scommentare successiva riga per ripristinare
                // this.handleMapGesture();
            }
            
        }, this));

        google.maps.event.addListener(this.map, 'dragend', $.proxy(function () {
            this.handleMapGesture();
        }, this));
    }

    handleMapGesture() {
        if (this.pansearch.is(':checked')) {
            const c = this.map.getCenter();
            this.center = { lat: c.lat(), lng: c.lng() };
            this.zoom = this.map.zoom;
    
            clearInterval(this.timeoutpan);
            
            window.ux.skeletonContentList(window.ss.results_el);

            this.timeoutpan = setTimeout($.proxy(function() {
                window.ss.flush(this.center.lat, this.center.lng);
    
                window.ss.changePageTitle(null, false);
            }, this), 500);
        }
    }

    highlightMarker(id, inside) {
        const mobj = this.markers[id];
        if (typeof mobj == typeof undefined) return;
        const m = mobj.marker;
        let mi = m.getIcon();
        //console.log('mi class at start: ', mi.className);
        const type = mi.className.replace('marker-icon ', '').replace('marker-icon-selected ', '');
        const colorset = this.getMarkerColor(type);

        if (inside) {
            mi.fillColor = colorset.highlight;
            mi.strokeColor = colorset.highlight;
            mi.className = 'marker-icon marker-icon-selected '+type;
            m.setZIndex(10);
        } else if (!inside) {
            mi.fillColor = colorset.color;
            mi.strokeColor = colorset.color;
            mi.className = 'marker-icon '+type;
            m.setZIndex(1);
        } 
        //console.log('mi class at close: ', mi.className);
        
        m.setIcon(mi);
    }

    getMarkerColor(type) {
        let colorset = {'color':'#F22233', 'highlight':'#211745' };
        if (type != 'store') {
            colorset.color = '#05A2BA';
            colorset.highlight = '#005459';
        }

        return colorset;
    }

    createMarker(id, coords) {
        if (this.markers[id]) return;

        const ow = 60;
        const oh = 35;
        const row = ow * 5;
        const roh = oh * 2;
        const color = this.getMarkerColor(coords.type)['color'];

        const iconPin = {
            path: 'M57.3,2.6H6.5C4,2.6,1.9,4.7,1.9,7.3V26c0,2.6,2.1,4.6,4.6,4.6h17.4c3.5,4.1,7,8,8,8c1,0,4.4-3.8,7.9-8h17.4c2.6,0,4.6-2.1,4.6-4.6V7.3C61.9,4.7,59.9,2.6,57.3,2.6z',
            size: new google.maps.Size(ow, oh),
            scaledSize: new google.maps.Size(ow, oh),
            anchor: new google.maps.Point(ow / 2, oh),
            labelOrigin: new google.maps.Point(ow / 2, (oh / 2) - 2),
            fillColor: color,
            strokeColor: color,
            fillOpacity: 1,
            className: 'marker-icon '+coords.type
        };

        const marker = new google.maps.Marker({
            map: this.map,
            label: {
                text: coords.dist.replace('.', ','),
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "700",
                className: 'marker-label',
            },
            position: new google.maps.LatLng(coords.lat, coords.lng),
            icon: iconPin,
            zIndex: 1
        });

        marker.addListener("click", () => {
            if ($(window).width() >= 992) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('[data-id="'+id+'"]').first().offset().top - 100
                }, 300);
            } else {
                let el = $('[data-id="'+id+'"]').first();
                el.closest('.async_content_list').animate({
                    scrollLeft: el.position().left
                });
            }
        });

        marker.addListener("mouseover", () => {
            window.gmac.highlightMarker(id, true);
        });

        marker.addListener("mouseout", () => {
            window.gmac.highlightMarker(id, false);
        });

        this.bounds.extend(marker.position);
        this.fittingbounds = true;
        setInterval($.proxy(function() { this.fittingbounds = false; }, this), 500);

        //
        // commentato per evitare che la mappa non rimanga nella posizione impostata dall'utente
        //
        //this.map.fitBounds(this.bounds);

        this.markers[id] = {'id':id, 'coords':coords, 'marker':marker};
    }

    centerTo(lat, lng) {
        var p = new google.maps.LatLng(lat, lng);
        this.map.panTo(p);
        this.center = { lat: lat, lng: lng };
    }

    emptyMarkers() {
        $.each(this.markers, function() {
            this.marker.setMap(null);
        });

        this.markers = {};

        this.bounds = new google.maps.LatLngBounds();
    }
}

export default GoogleMapsAsideManager;